<template lang="pug">
.code-insert-platform.mt-3.w-100
  om-body-text(v-if="!onlyCode" bt400lg) {{ content || $t('codeInsertV2.insertJS') }}
  highlight.w-100.mt-4(v-if="userId !== null" :codeStr="code")
</template>
<script>
  import Highlight from '@/components/Highlight.vue';
  import _insertCodes from '@/config/insertCodes';
  import { mapGetters } from 'vuex';
  import platformMixin from '@/mixins/codeinsert/platform';

  export default {
    components: {
      Highlight,
    },
    mixins: [platformMixin],
    props: {
      onlyCode: { type: Boolean, default: false },
      type: { type: String, default: 'custom' },
    },
    computed: {
      ...mapGetters(['domainBase']),
      ...mapGetters({ userId: 'databaseId' }),
    },
    watch: {
      domainBase: {
        deep: true,
        handler() {
          this.code = _insertCodes(this.type, this.userId, this.domainBase);
        },
      },
    },
    created() {
      this.code = _insertCodes(this.type, this.userId, this.domainBase);
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/_colors'

  .site-text
    font-weight: 700
    font-size: 16px
    color: $om-gray-700
</style>
