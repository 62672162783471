import runtimeConfig from './runtime';

export default (type, userId, domain) => {
  const prefix = domain === 'optimonk.com' ? 'front.' : '';
  const postfix = domain === 'optimonk.com' ? '' : '/front';
  if (type === 'custom') {
    return `<script type="text/javascript"
src="${runtimeConfig.VUE_APP_FRONTEND_ONSITE_URL}/script.js?account=${userId}"
async></script>`;
  }
  if (type === 'unas') {
    return `${runtimeConfig.VUE_APP_FRONTEND_ONSITE_URL}/script.js?account=${userId}`;
  }
  if (type === 'shopify') {
    return `(function(e,a){
      var t,r=e.getElementsByTagName("head")[0],c=e.location.protocol;
      t=e.createElement("script");t.type="text/javascript";
      t.charset="utf-8";t.async=!0;t.defer=!0;
      t.src=c+"//${prefix}${domain}${postfix}/public/"+a+"/shopify/preload.js";r.appendChild(t);
  })(document,"${userId}");`;
  }
};
